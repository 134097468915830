import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Page, View, Text } from 'lib'

const Start = () => {
  const styles = useStyles()

  return (
    <Page center emptyNavbar>
      <View style={styles.circularWrapper}>
        <Text variant='h4' gutterBottom> Down for maintenance</Text>
        <Text>Stablegains will be back online soon!</Text>
      </View>
    </Page>
  )
}

const useStyles = makeStyles((theme) => ({
  circularWrapper: {
    marginTop: '50px',
    textAlign: 'center',
  },
}))

export default Start
